import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import generalMeetings from '../../mockData/generalMeetings';
import hallwayNo from '../../images/hallwayNo.jpg';
import { COLOR_CORAL, COLOR_RED_VISITED } from '../../utils/constants';

const useStyles = makeStyles({
  agmContainer: {
    marginBottom: 40,
  },
  container: {
    marginTop: 80,
    marginBottom: 40,
    '@media (max-width:600px)': {
      marginTop: 40,
    },
  },
  header: {
    marginTop: 0,
    marginBottom: 20,
  },
  textNoMargin: {
    margin: 0,
  },
  imageGrid: {
    padding: '20px 100px 40px 0px',
    '@media (max-width:600px)': {
      padding: '20px 0px 20px 0px',
    },
  },
  textGrid: {
    padding: '20px 100px 40px 0px',
    maxWidth: 720,
    '@media (max-width:600px)': {
      padding: '20px 20px 20px 20px',
    },
  },
  link: {
    color: COLOR_CORAL,
    textDecoration: 'none',
    lineHeight: 1,
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: COLOR_CORAL,
    },
    '&:visited': {
      color: COLOR_RED_VISITED,
    },
  },
  linkContainer: {
    paddingTop: 4,
    paddingBottom: 4,
    // backgroundColor: 'red',
  },
});

export default () => {
  const classes = useStyles();
  return (
    <Grid container justify="flex-start" className={classes.container}>
      <Grid item md={6} className={classes.imageGrid}>
        <img src={hallwayNo} alt="Hallway" />
      </Grid>
      <Grid item md={6} className={classes.textGrid}>
        <h3 className={classes.header}>General meetings</h3>
        {/* Extra non cms document field */}
        <p className={classes.textNoMargin}>
          Extraordinary General Meeting 19 November 2024
        </p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FVow%20ASA%20-%20Notice%20EGM%202024.pdf?alt=media&token=1150d1c9-e475-4ee4-86c5-92fa0cdda1e7"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA – Notice of EGM 2024
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FNomination%20Committee%20Proposal%20Vow%20ASA%20EGM%202024.pdf?alt=media&token=38fb604a-6b51-4532-a19b-4b2b6c9c184e"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FArticles%20of%20Association%20for%20Vow%20ASA.pdf?alt=media&token=081b9d49-71e9-4740-88d9-f06614d2ae71"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Articles of Association
            </a>
          </Grid>
        </Grid>
        <p className={classes.textNoMargin}>Annual General Meeting 2024</p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FVow%20ASA%20-%20Minutes%20of%20AGM%202024.pdf?alt=media&token=f925aa92-5884-4acc-b6cb-4657042be814"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Minutes AGM 2024
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FVOW%20ASA%20-%20AGM%202024%20represented%20list.pdf?alt=media&token=92ae6efb-02c4-4359-bc0f-3ffea54fc391"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              AGM 2024 representation list
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FRemuneration%20report%202023.pdf?alt=media&token=cc239d22-493a-4582-b7fa-d326a8887a7e"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Guidelines and Report on Remuneration
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FRecommendation%20on%20selection%20of%20auditor-Vow%20ASA.pdf?alt=media&token=35182cc6-9894-41d3-8237-1deb55c5008a"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Recommendation on selection of auditor
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FNomination%20Committee%20Proposal%20Vow%20ASA%202024_NOR.pdf?alt=media&token=704a4920-39da-46eb-8a66-bc81152419aa"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal NOR
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FNomination%20Committee%20Proposal%20Vow%20ASA%202024_ENG.pdf?alt=media&token=377d138a-c2d0-4f06-b34e-6599803ed431"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal ENG
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Vow%20-%20Notice%20to%20AGM%202024.pdf?alt=media&token=5638b7fe-f5b1-461c-9495-0f0f7ef0d78d"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Notice of AGM 2024
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://investor.vps.no/gm/logOn.htm?token=d27b97e6644c9678b7145bfc29696d93b06c0c6c&validTo=1719054000000&oppdragsId=20240208VPP2L0U0"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              AGM registration link
            </a>
          </Grid>
        </Grid>

        <p className={classes.textNoMargin}>
          Extraordinary General Meeting 29 November 2023
        </p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2024%2FVow_ASA_-_Minutes_EGM_291123.pdf?alt=media&token=ba38f9bb-4ce1-4cdc-8bd6-700feb4c2f35"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Minutes EGM 2023
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FVow%20ASA%20-%20Notice%20of%20EGM%202023.pdf?alt=media&token=411408a9-4902-4b2f-9193-7fdad6dbd47d"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA – Notice of EGM 2023
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FNomination%20Committee%20Proposal%20EGM%202023%20NOR.pdf?alt=media&token=987512c7-7cb3-4684-8e9a-64fba5dfddae"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal EGM 2023 NOR
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FNomination%20Committee%20Proposal%20EGM%202023%20ENG.pdf?alt=media&token=d0500544-4d29-4823-8585-67d4f4fdf782"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal EGM 2023 ENG
            </a>
          </Grid>
        </Grid>
        <p className={classes.textNoMargin}>Annual General Meeting 2023</p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FVow_ASA_-_Minutes_AGM_220523.pdf?alt=media&token=38e34034-8965-460f-b937-9bb0d8e349ec"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Minutes AGM 220523
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FGuidelines_and_Report_on_Remuneration_2023.pdf?alt=media&token=70bd9c63-ce2c-4eca-8bd1-a3dd705b0d79"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Guidelines and Report on Remuneration
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2Fvow-asa-notice-of-agm-2023.pdf?alt=media&token=d4179053-eceb-4b1c-b43b-8ccb8e8895a0"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Notice of AGM 2023
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FNomination_Committee_Proposal_Norwegian.pdf?alt=media&token=ff61d5a7-9e5e-4826-8f94-13d2741a036e"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal NOR
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2023%2FNomination_Committee_Proposal_English.pdf?alt=media&token=ff966315-4150-4fd6-ad0c-346dc6a6b60b"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee Proposal ENG
            </a>
          </Grid>
        </Grid>
        <p className={classes.textNoMargin}>Annual General Meeting 2022</p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2022%2FVow_ASA_-_Minutes_AGM_190522.pdf?alt=media&token=d0fbbed3-709c-4c08-bf1e-f23df99028fc"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Minutes AGM 190522
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2022%2FGuidelines_and_Report_on_Remuneration_2022.pdf?alt=media&token=1b3a0c55-767e-4e09-a79f-594c04f06c92"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Guidelines and Report on Remuneration
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2022%2FNomination_Committee_Proposal_2022.pdf?alt=media&token=8f74fefc-ec59-43ec-9aaf-cdf854dc4f8c"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee´s Proposal NOR
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2022%2FNomination_Committee_Proposal_2022_EnglishTranslation.pdf?alt=media&token=76c347d3-7ccb-403a-b9bd-41f02d9519a2"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee´s Proposal ENG
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2022%2FVow%20ASA%20-%20Notice%20of%20AGM%202022.pdf?alt=media&token=3621f88c-3375-4a69-b7f2-847abbc416d1"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Notice of AGM 2022
            </a>
          </Grid>
        </Grid>
        <p className={classes.textNoMargin}>Annual General Meeting 2021</p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FFisjonsplan-Demerger%20plan_AGM140521.pdf?alt=media&token=0365e59b-1c18-43b8-8401-31c617089d54"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Fisjonsplan / Demerger plan
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FNominationCommitteeProposal2021.pdf?alt=media&token=fea5c49b-651a-4f21-934d-8f2cf18573a4"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee’s proposal NOR
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FNominationCommitteeProposal2021EnglishTranslation.pdf?alt=media&token=eab3d556-5804-464a-ba5b-3cb0f0007bd0"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee’s proposal ENG
            </a>
          </Grid>

          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FVow%20ASA%20-%20Notice%20of%20AGM%202021.pdf?alt=media&token=a5a4e54e-1791-4234-b27b-210f17c82c76"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Notice of AGM 2021
            </a>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.linkContainer}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2021%2FVow_ASA_-_Minutes_AGM_140521.pdf?alt=media&token=70d10b2c-6871-42fe-a06b-656d54b2b2cb"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Minutes AGM 140521
            </a>
          </Grid>
        </Grid>
        <p className={classes.textNoMargin}>
          Extraordinary General Meeting 8 December 2020
        </p>
        <Grid container className={classes.agmContainer}>
          <Grid itemxs={12} sm={6}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Vow%20ASA%20-%20Notice%20of%20EGM%20081220.pdf?alt=media&token=bddce403-7ded-48a9-9da9-1e27b3076812"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Notice of EGM 2020
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Innstilling%20fra%20Valgkomiteen%20i%20Vow%20ASA.pdf?alt=media&token=fa07e379-6e5b-4436-8e61-118e9a41693b"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Committee’s proposal
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/2020%2FVow%20ASA%20-%20Minutes%20EGM%20081220.pdf?alt=media&token=08f0abd8-7a6b-4d0c-9694-4649adf4a68f"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Vow ASA - Minutes EGM 081220
            </a>
          </Grid>
        </Grid>
        <p className={classes.textNoMargin}>Annual General Meeting 2020</p>
        <Grid container className={classes.agmContainer}>
          <Grid item xs={12} sm={6}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Vow-ASA-Minutes-AGM-250520.pdf?alt=media&token=32a9379b-790b-4611-858b-599f5fd18c15"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              2020 Vow ASA - Minutes AGM 2020
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Vow-valgkomite-innstilling-2020.pdf?alt=media&token=df210940-89d6-462d-b39e-477465764f58"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Nomination Commitee's proposal 2020
            </a>
          </Grid>
          <Grid item xs={12} sm={6}>
            <a
              href="https://firebasestorage.googleapis.com/v0/b/vow-asa.appspot.com/o/Vow-ASA-Notice-of-AGM-2020.pdf?alt=media&token=e0a3495b-924b-4df6-8aa8-f9a2e4eaa4a6"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Notice of AGM 2020
            </a>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          {generalMeetings.map(item => (
            <Grid item sm={6}>
              <p className={classes.textNoMargin}>{item.date}</p>
              <a
                href={item.link}
                className={classes.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                <p className={classes.textNoMargin}>{item.name}</p>
              </a>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
